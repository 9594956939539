<template>
  <div class="page">
    <div class="w-90 h-full md:pb-44">
      <Banner action="getHomeSlide" getter="homeSlide"/>

      <div class="home-media-list relative z-10 space-y-12 mt-10">
        <!-- Async section -->
        <Section class="latest-history" title="Сүүлд үзсэн" v-if="$store.state.user != null" :ep-number="true"
                 action="getLatestHistory" getter="latestHistoryEpisodes"/>

        <Section title="Шинээр нэмэгдсэн" action="getLatestMedia" getter="latestMedias" moreLink="/series/all/all/all/new"/>
        <!--        <Section title="Энэ улиралд" :num="false" action="getSeasonMedia" getter="seasonMedias"/>-->
        <!--        <Section title="Шинэ кино" height="140" action="getLatestMovies" getter="latestMovies"/>-->

        <!-- Non async section container -->
        <SectionContainer action="getHomeCatMedias" getter="homeCatMedias"/>
      </div>
    </div>
  </div>
</template>

<script>
import Section from "@components/Section.vue";
import SectionContainer from "@components/SectionContainer.vue";
import Banner from "@components/BannerMulti.vue";
import useDevice from "@hooks/useDevice";

export default {
  components: {
    Section,
    SectionContainer,
    Banner,
  },
  setup() {
    const {isDesktop} = useDevice();
    return {isDesktop};
  },

  created() {
  },
};
</script>
<style>
</style>